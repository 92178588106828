<template>
  <v-form ref="form" v-model="validCheck" lazy-validation>
    <v-row class="px-4">
      <v-col>
        <span class="font-ubuntu-medium font-size-20">Firewall Settings</span>
        <v-row class="pt-4 d-flex align-center">
          <v-col cols="4 pb-0">
            <!--FIREWALL-->
            <label class="font-roboto-condensed-regular font-size-15"
              >Firewall</label
            >
            <v-select
              v-model="firewall"
              :disabled="checkValid"
              :items="firewalls"
              :rules="firewallRules"
              item-text="name"
              item-value="id"
              label="Firewall"
              placeholder="Firewall"
              dense
              solo
            />
            <!--END FIREWALL-->
          </v-col>
          <v-col cols="4">
            <div class="toggle mt-2">
              <v-switch
                v-model="showAll"
                :disabled="checkValid"
                label="Show all"
                true-value="Yes"
                false-value="No"
                inset
                hide-details
                :class="[showAll === 'Yes' ? 'v-switch-on' : 'v-switch-off']"
                class="target-background v-switch-off"
                @click="showAllChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row class="px-4 pb-4">
          <v-col cols="4">
            <label class="font-ubuntu-medium font-size-20">
              Source Settings</label
            >
            <v-col cols="12" class="pa-0 pt-4">
              <!--SOURCE ZONE-->
              <label class="font-roboto-condensed-regular font-size-15"
                >Source Zone</label
              >
              <v-select
                v-model="sourceZone"
                :disabled="checkValid"
                :items="zones"
                item-text="name"
                item-value="id"
                label="Source Zone"
                placeholder="Source Zone"
                dense
                solo
              />
              <!--END SOURCE ZONE-->
            </v-col>
            <v-col cols="12" class="pa-0">
              <!--SOURCE IP-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="py-0 px-0 pb-2">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Source Ip</label
                  >
                  <v-text-field
                    v-model="sourceIp"
                    :rules="[sourceIpRules.required, ipValidate]"
                    label="Source Ip"
                    placeholder="Source Ip"
                    dense
                    solo
                    autocomplete="new-password"
                  />
                </v-col>
              </v-row>
              <!--END SOURCE IP-->
            </v-col>
            <v-col cols="12" class="pa-0">
              <!--SOURCE USER-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Source User</label
                  >
                  <v-text-field
                    v-model="sourceUser"
                    label="Source User"
                    placeholder="Source User"
                    dense
                    solo
                    autocomplete="new-password"
                  />
                </v-col>
                <!--END SOURCE USER-->
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="4">
            <span class="font-ubuntu-medium font-size-20"
              >Destination Settings</span
            >
            <v-col cols="12" class="pa-0 pt-4">
              <!--DESTINATION ZONE-->
              <label class="font-roboto-condensed-regular font-size-15"
                >Destination Zone</label
              >
              <v-select
                v-model="destinationZone"
                :disabled="checkValid"
                :items="zones"
                item-text="name"
                item-value="id"
                label="Destination Zone"
                placeholder="Destination Zone"
                dense
                solo
              />
              <!--END DESTINATION ZONE-->
            </v-col>
            <v-col cols="12" class="pa-0">
              <!--DESTINATION IP-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="py-0 px-0 pb-2">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Destination Ip</label
                  >
                  <v-text-field
                    v-model="destinationIp"
                    :rules="[destinationIpRules.required, ipValidate]"
                    label="Destination Ip"
                    placeholder="Destination Ip"
                    dense
                    solo
                    autocomplete="new-password"
                  />
                </v-col>
              </v-row>
              <!--END DESTINATION IP-->
            </v-col>
            <v-col cols="12" class="pa-0">
              <!--DESTINATION PORT-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Destination port</label
                  >
                  <v-text-field
                    v-model="destinationPort"
                    :rules="[
                      destinationPortRules.required,
                      destinationPortRules.number,
                    ]"
                    label="Destination port"
                    placeholder="Destination port"
                    dense
                    solo
                    autocomplete="new-password"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="4">
            <span class="font-ubuntu-medium font-size-20">Other Settings</span>
            <v-row>
              <v-col cols="12" class="pb-0 pt-7">
                <!--PROTOCOL-->
                <label class="font-roboto-condensed-regular font-size-15"
                  >Protocol</label
                >
                <v-select
                  v-model="protocol"
                  :disabled="checkValid"
                  :items="protocols"
                  :rules="protocolRules"
                  item-text="name"
                  item-value="value"
                  label="Protocol"
                  placeholder="Protocol"
                  dense
                  solo
                />
                <!--END PROTOCOL-->
              </v-col>
              <v-col cols="12" class="pt-0">
                <!--CATEGORY-->
                <v-row class="mt-0 mx-0">
                  <v-col cols="12" class="py-0 px-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Category</label
                    >
                    <v-text-field
                      v-model="category"
                      label="Category"
                      placeholder="Category"
                      dense
                      solo
                      autocomplete="new-password"
                    />
                  </v-col>
                </v-row>
                <!--END CATEGORY-->
              </v-col>
              <v-col cols="12" class="pt-0">
                <!--APPLICATION-->
                <v-row class="mt-0 mx-0">
                  <v-col cols="12" class="py-0 px-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Application</label
                    >
                    <v-text-field
                      v-model="application"
                      label="Application"
                      placeholder="Application"
                      dense
                      solo
                      autocomplete="new-password"
                    />
                  </v-col>
                </v-row>
                <!--END APPLICATION--></v-col
              >
            </v-row>
          </v-col>
          <v-row class="mx-0 pb-4 px-4">
            <v-col cols="12" class="pb-5 pt-0 px-0">
              <v-icon v-if="saving" color="primary">
                fas fa-spinner fa-spin
              </v-icon>
              <span
                v-if="saving"
                class="font-roboto-condensed-light font-size-15 ml-2"
              >
                Processing...
              </span>
              <v-btn
                v-if="!saving"
                color="primary"
                class="text-capitalize mt-2 px-5"
                @click="validate()"
              >
                {{ "Test" }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="response">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :data="fullresponse"
                :items="fullresponse"
                hide-default-footer
                item-key="id"
                :loading="saving"
                loading-text="Loading data from API...please wait"
                class="synapsa-table"
              >
                <template v-slot:item.name="{ item }">
                  <span>
                    {{ item.attributes.name }}
                  </span>
                </template>
                <template v-slot:item.src_addr="{ item }">
                  <span
                    v-for="(result, index) in item.source.member"
                    :key="index"
                  >
                    {{ result }}
                  </span>
                </template>
                <template v-slot:item.dst_intf>
                  <span>
                    {{ destinationZone }}
                  </span>
                </template>
                <template v-slot:item.src_intf>
                  <span>
                    {{ sourceZone }}
                  </span>
                </template>
                <template v-slot:item.dst_addr="{ item }">
                  <span
                    v-for="(result, index) in item.destination.member"
                    :key="index"
                  >
                    {{ result }}
                  </span>
                </template>
                <template v-slot:item.dst_port>
                  <span>
                    {{ destinationPort }}
                  </span>
                </template>
                <template v-slot:item.protocol>
                  <span>
                    {{ protocol }}
                  </span>
                </template>
                <template v-slot:item.category="{ item }">
                  <span>
                    {{ item.category }}
                  </span>
                </template>
                <template v-slot:item.user="{ item }">
                  <span>
                    {{ item.user }}
                  </span>
                </template>
                <template v-slot:item.app>
                  <span>
                    {{ application ? application : "any" }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { paloZones } from "@/api/toolbox/policyTester";
import { paloTest } from "@/api/toolbox/policyTester";
import { Errors } from "@/utils/error";
import { Validation } from "@/utils/validation";
export default {
  name: "paloaltoForm",
  props: {
    firewalls: {
      type: Array,
      default: function () {
        return [];
      },
    },
    vendor: {
      type: Number,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      checkValid: null,
      valid: true,
      validCheck: true,
      saving: false,
      firewall: null,
      firewallRules: [(v) => !!v || "Firewall is required"],
      showAll: "No",
      // showAllOptions: [
      //   { name: "Yes", value: 1 },
      //   { name: "No", value: 0 },
      // ],
      zones: [],
      sourceZone: null,
      sourceIp: "",
      sourceUser: null,
      destinationZone: null,
      destinationIp: "",
      destinationPort: null,
      protocol: null,
      protocolRules: [(v) => !!v || "Protocol is required"],
      sourceIpRules: {
        required: (v) => !!v || "Source Ip is required",
      },
      destinationIpRules: {
        required: (v) => !!v || "Destination Ip is required",
      },
      destinationPortRules: {
        required: (v) => !!v || "Destination port is required",
        number: (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
      },
      protocols: [
        { name: "TCP", value: 6 },
        { name: "UDP", value: 17 },
      ],
      category: null,
      application: null,
      response: false,
      fullresponse: [],
      headers: [
        { text: "Policy name", value: "name" },
        { text: "Source Zone", value: "src_intf" },
        { text: "Source IP", value: "src_addr" },
        { text: "Source User", value: "user" },
        { text: "Destination Zone", value: "dst_intf" },
        { text: "Destination IP", value: "dst_addr" },
        { text: "Destination Port", value: "dst_port" },
        { text: "Protocol", value: "protocol" },
        { text: "App", value: "app" },
        { text: "Category", value: "category" },
        { text: "Action", value: "action" },
      ],
    };
  },
  watch: {
    firewall: {
      immediate: false,
      deep: true,
      handler(newValue) {
        this.getZones(newValue);
      },
    },
  },
  mounted() {},
  methods: {
    getZones(id) {
      paloZones(id)
        .then((response) => {
          this.zones = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.test();
      }
    },
    showAllChange() {
      console.log(this.showAll);
    },

    test() {
      this.saving = true;
      this.fullresponse = [];
      const data = {
        firewall_id: this.firewall,
        vendor_id: this.vendor,
        showall: this.showAll,
        source_zone: this.sourceZone,
        source_ip: this.sourceIp,
        source_user: this.sourceUser,
        destination_zone: this.destinationZone,
        destination_ip: this.destinationIp,
        destination_port: this.destinationPort,
        protocol: this.protocol,
        category: this.category,
        application: this.application,
      };
      paloTest(data)
        .then((response) => {
          this.response = true;
          if (response.empty === "false") {
            this.fullresponse.push(response.fullresponse.rules.entry);
          } else {
            this.$toast.open({
              message: "No Matching Security policy found",
              type: "info",
              position: "top-right",
              duration: 2000,
            });
          }
          this.saving = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    ipValidate(val) {
      return Validation.ip(val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
