<template>
  <v-form ref="form" v-model="validCheck" lazy-validation>
    <v-row>
      <v-col cols="4">
        <span class="font-ubuntu-medium font-size-20">Firewall Settings</span>
        <v-row class="d-flex flex-column">
          <v-col cols="12" class="pb-0">
            <!--FIREWALL-->
            <label class="font-roboto-condensed-regular font-size-15"
              >Firewall</label
            >
            <v-select
              v-model="firewall"
              :disabled="checkValid"
              :items="firewalls"
              :rules="firewallRules"
              item-text="name"
              item-value="id"
              label="Firewall"
              placeholder="Firewall"
              dense
              solo
            />
            <!--END FIREWALL-->
          </v-col>

          <v-col cols="12" class="d-flex flex-column pt-0 mt">
            <span class="font-ubuntu-medium font-size-20">Other Settings</span>
            <!--PROTOCOL-->
            <label class="font-roboto-condensed-regular font-size-15"
              >Protocol</label
            >
            <v-select
              v-model="protocol"
              :disabled="checkValid"
              :items="protocols"
              :rules="protocolRules"
              item-text="name"
              item-value="id"
              label="Protocol"
              placeholder="Protocol"
              dense
              solo
            />
            <!--END PROTOCOL-->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <span class="font-ubuntu-medium font-size-20">Source Settings</span>
        <v-row>
          <v-col cols="12">
            <!--SOURCE INTERFACE-->
            <label class="font-roboto-condensed-regular font-size-15"
              >Source Interface</label
            >
            <v-select
              v-model="sourceInterface"
              :disabled="checkValid"
              :items="zones"
              :rules="sourceInterfaceRules"
              item-text="name"
              item-value="id"
              label="Source Interface"
              placeholder="Source Interface"
              dense
              solo
            />
            <!--END SOURCE INTERFACE-->
          </v-col>
          <v-col cols="12" class="pt-1">
            <!--SOURCE IP-->
            <v-row class="mt-0 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Source Ip</label
                >
                <v-text-field
                  v-model="sourceIp"
                  :rules="[sourceIpRules.required, ipValidate]"
                  label="Source Ip"
                  placeholder="Source Ip"
                  dense
                  solo
                  aria-autocomplete="none"
                />
              </v-col>
            </v-row>
            <!--END SOURCE IP-->
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <span class="font-ubuntu-medium font-size-20"
          >Destination Settings</span
        >
        <v-row>
          <v-col cols="12" class="mb-3">
            <!--DESTINATION IP-->
            <v-row class="mt-0 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Destination Ip</label
                >
                <v-text-field
                  v-model="destinationIp"
                  :rules="[destinationIpRules.required, ipValidate]"
                  label="Destination Ip"
                  placeholder="Destination Ip"
                  dense
                  solo
                  aria-autocomplete="none"
                />
              </v-col>
            </v-row>
            <!--END DESTINATION IP-->
          </v-col>
          <v-col cols="12" class="pt-1">
            <!--DESTINATION PORT-->
            <v-row class="mt-0 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Destination port</label
                >
                <v-text-field
                  v-model="destinationPort"
                  :rules="[
                    destinationPortRules.required,
                    destinationPortRules.number,
                  ]"
                  label="Destination port"
                  placeholder="Destination port"
                  dense
                  solo
                  aria-autocomplete="none"
                />
              </v-col>
            </v-row>
            <!--END DESTINATION PORT--></v-col
          >
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mx-0 mt-0 pb-4">
      <v-col cols="12" class="pb-5 pt-0 px-0">
        <v-icon v-if="saving" color="primary"> fas fa-spinner fa-spin </v-icon>
        <span
          v-if="saving"
          class="font-roboto-condensed-light font-size-15 ml-2"
        >
          Processing...
        </span>
        <v-btn
          v-if="!saving"
          color="primary"
          class="text-capitalize mt-2 px-5"
          @click="validate()"
        >
          {{ "Test" }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="response">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :data="fullresponse"
          :items="fullresponse"
          hide-default-footer
          item-key="id"
          :loading="saving"
          loading-text="Loading data from API...please wait"
          class="synapsa-table"
        >
          <template v-slot:item.policyname="{ item }">
            <span>
              {{ item.policyname }}
            </span>
          </template>
          <template v-slot:item.src_intf="{ item }">
            <span v-for="(result, index) in item.src_intf" :key="index">
              {{ result.name }}
            </span>
          </template>
          <template v-slot:item.src_addr="{ item }">
            <span v-for="(result, index) in item.src_addr" :key="index">
              {{ result.name }}
            </span>
          </template>
          <template v-slot:item.dst_intf="{ item }">
            <span v-for="(result, index) in item.dst_intf" :key="index">
              {{ result.name }}
            </span>
          </template>
          <template v-slot:item.dst_addr="{ item }">
            <span v-for="(result, index) in item.dst_addr" :key="index">
              {{ result.name }}
            </span>
          </template>
          <template v-slot:item.dst_port>
            <span>
              {{ destinationPort }}
            </span>
          </template>
          <template v-slot:item.protocol>
            <span>
              {{ protocol }}
            </span>
          </template>
          <template v-slot:item.service="{ item }">
            <span v-for="(result, index) in item.service" :key="index">
              {{ result.name }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { fortiZones } from "@/api/toolbox/policyTester";
import { fortiTest } from "@/api/toolbox/policyTester";
import { Errors } from "@/utils/error";
import { Validation } from "@/utils/validation";
export default {
  name: "paloaltoForm",
  props: {
    firewalls: {
      type: Array,
      default: function () {
        return [];
      },
    },
    vendor: {
      type: Number,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      checkValid: null,
      valid: true,
      validCheck: true,
      saving: false,
      firewall: null,
      firewallRules: [(v) => !!v || "Firewall is required"],
      zones: [],
      sourceInterface: null,
      sourceInterfaceRules: [(v) => !!v || "Source Interface is required"],
      sourceIp: "",
      destinationIp: "",
      destinationPort: null,
      protocol: null,
      protocolRules: [(v) => !!v || "Protocol is required"],
      protocols: [
        { name: "TCP", value: "tcp" },
        { name: "UDP", value: "udp" },
      ],
      sourceIpRules: {
        required: (v) => !!v || "Source Ip is required",
      },
      destinationIpRules: {
        required: (v) => !!v || "Destination Ip is required",
      },
      destinationPortRules: {
        required: (v) => !!v || "Destination port is required",
        number: (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
      },
      response: false,
      fullresponse: [],
      headers: [
        { text: "Policy name", value: "name" },
        { text: "Source Zone", value: "src_intf" },
        { text: "Source IP", value: "src_addr" },
        { text: "Destination Zone", value: "dst_intf" },
        { text: "Destination IP", value: "dst_addr" },
        { text: "Destination Port", value: "dst_port" },
        { text: "Protocol", value: "protocol" },
        { text: "Service", value: "service" },
        { text: "Action", value: "action" },
      ],
    };
  },
  watch: {
    firewall: {
      immediate: false,
      deep: true,
      handler(newValue) {
        this.getZones(newValue);
      },
    },
  },
  mounted() {},
  methods: {
    getZones(id) {
      fortiZones(id)
        .then((response) => {
          this.zones = response.data.results;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.test();
      }
    },
    test() {
      this.saving = true;
      this.fullresponse = [];
      const data = {
        firewall_id: this.firewall,
        vendor_id: this.vendor,
        source_interface: this.sourceInterface,
        source_ip: this.sourceIp,
        destination_ip: this.destinationIp,
        destination_port: this.destinationPort,
        protocol: this.protocol,
      };
      fortiTest(data)
        .then((response) => {
          if (response.empty === "false") {
            this.fullresponse.push(response);
          } else {
            this.$toast.open({
              message: "No Matching Security policy found",
              type: "info",
              position: "top-right",
              duration: 2000,
            });
          }
          this.response = true;
          this.saving = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    ipValidate(val) {
      return Validation.ip(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.mt {
  margin-top: -12px !important;
}
</style>
