<template>
  <v-container fluid>
    <v-row class="form-block py-5">
      <v-col cols="12">
        <v-row v-if="!loading" align="center" justify="center" class="mx-0">
          <v-col cols="12" class="synapsa-tabs toolbox-tabs mt-0">
            <v-btn
              v-for="tab in tabs"
              :key="tab.value"
              :text="tab.value !== activeTab"
              :color="tab.value === activeTab ? 'primary' : ''"
              :outlined="tab.value === activeTab"
              small
              class="text-capitalize synapsa-tab"
              :class="tab.value === activeTab ? 'elevation-1' : ''"
              @click="to(tab.value)"
            >
              {{ tab.name }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <v-col
            cols="12"
            class="p-3 synapsa-toolbox synapsa-toolbox-policytester"
            :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
          >
            <paloaltoForm
              v-if="activeTab === 'paloalto' && !loading"
              :firewalls="firewalls"
              :vendor="vendorattribute_id"
              @stoploading="console($event)"
            />
            <fortinetForm
              v-if="activeTab === 'fortinet' && !loading"
              :firewalls="firewalls"
              :vendor="vendorattribute_id"
              @loading="loading = $event"
            />
            <v-row v-if="loading" class="mt-0 mx-0">
              <v-col cols="12">
                <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
                <span class="font-roboto-condensed-light font-size-15 ml-2"
                  >Loading...</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import paloaltoForm from "@/views/toolbox/policyTesterForms/paloaltoForm";
import fortinetForm from "@/views/toolbox/policyTesterForms/fortinetForm";
import { Errors } from "@/utils/error";
import { getFirewallVendors } from "@/api/settings/firewall";
import { getFirewallListByVendor } from "@/api/settings/firewall";
export default {
  name: "threatSimulator",
  components: {
    paloaltoForm,
    fortinetForm,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,
      valid: true,
      validCheck: true,
      saving: false,
      data: null,
      message: null,
      messageRules: [(v) => !!v || "Message is required"],
      tabs: [
        { name: "PaloAlto", value: "paloalto" },
        { name: "Fortinet", value: "fortinet" },
      ],
      activeTab: "paloalto",
      firewalls: [],
      vendorattribute_id: null,
    };
  },
  mounted() {
    this.getVendors();
  },
  watch: {
    activeTab: {
      immediate: false,
      deep: true,
      handler() {
        this.getVendors();
      },
    },
    vendorattribute_id: {
      immediate: false,
      deep: true,
      handler(newValue) {
        getFirewallListByVendor(newValue)
          .then((response) => {
            this.firewalls = response.data;
          })
          .catch((e) => {
            Errors.show(e);
          });
      },
    },
  },
  methods: {
    validate() {},
    to(url) {
      this.activeTab = url;
    },
    console(event) {
      //EVENT
      console.log(event);
    },
    getVendors() {
      getFirewallVendors()
        .then((response) => {
          const self = this;
          response.data.forEach(function (vendor) {
            if (vendor.namesafe === self.activeTab) {
              self.vendorattribute_id = vendor.id;
            }
          });
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.synapsa-toolbox-policytester {
  .v-input {
    font-size: 14px;
  }
  .v-label {
    font-size: 14px;
  }
}
</style>
