import request from "@/utils/request";

export function paloZones(id) {
  return request({
    url: "api/toolbox/policytester/paloaltozones/" + id,
    method: "get",
  });
}
export function fortiZones(id) {
  return request({
    url: "api/toolbox/policytester/fortinetzones/" + id,
    method: "get",
  });
}
export function paloTest(data) {
  return request({
    url: "api/toolbox/policytester/paloaltotest",
    method: "post",
    data: data,
  });
}
export function fortiTest(data) {
  return request({
    url: "api/toolbox/policytester/fortinettest",
    method: "post",
    data: data,
  });
}
